/** @jsx jsx */
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import Col from '@fify.at/gatsby-theme-base/src/components/Grid/Col';
import Row from '@fify.at/gatsby-theme-base/src/components/Grid/Row';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function ListSection({ title, header, text, ...props }) {
  return (
    <Section {...props} container="small">
      <div sx={{ marginBottom: 7 }}>
        <Heading
          sx={{
            color: '',
          }}
        >
          {title}
        </Heading>
      </div>
      {header && <p sx={{ '&&&': { fontWeight: 'bold' } }}>{header}</p>}

      <ul className="fa-ul">
        {text &&
          text.map(
            textRow =>
              textRow.listItem && (
                <li>
                  <p>
                    <Icon
                      sx={{ color: 'primary', marginRight: 2 }}
                      variant="fa-li fad fa-lg"
                      icon="fa-check-double"
                    />{' '}
                    {textRow.listItem}
                  </p>
                </li>
              )
          )}
      </ul>
    </Section>
  );
}

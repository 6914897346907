/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import ListSection from '../../../components/ListSection';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';

export default function IndividuelleBeratung({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Individuelle Beratung beim Verkauf Ihrer Immobilie"
      description="Individuelle Beratung wird bei WirkaufendeinHaus.at groß geschrieben. Wir helfen Ihnen bei der Entscheidung und kaufen Ihr Haus schnell und unkompliziert."
      image={data.hero.childImageSharp.fluid}
      keywords={['Individuelle', 'Beratung']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Besichtigung & Individuelle Beratung"
        subtitle="beim Verkauf Ihrer Immobilie"
        image={data.hero}
      />

      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>Individuelle Beratung</Heading>
          <Subheading>von Menschen für Menschen</Subheading>
        </div>
        <p>
          Individuelle Beratung wird bei WirkaufendeinHaus.at groß geschrieben.
          Bei uns ist Ihr Anliegen rund um das Thema Immobilie in den besten
          Händen.
        </p>
        <p>
          Unser Anspruch ist es, die materiellen und emotionalen Werte zu wahren
          und einen vertrauensvollen, professionellen Umgang zu gewährleisten.
          Denn letztendlich sollen Sie sich bei Ihrer Entscheidung wohlfühlen.
        </p>
        <p>
          Transparenz, Zuverlässigkeit und Diskretion sind uns dabei besonders
          wichtig.
          <br />
          Unsere langjährige Kompetenz, regionale Marktkenntnisse und eine
          ganzheitliche Einschätzung der Situation begleiten Sie bei jedem
          Schritt des Ankaufsprozesses.
        </p>
        <p>
          Unsere Partnerunternehmen und regionalen Immobilienexperten haben den
          Auftrag, Ihre Interessen uns gegenüber zu vertreten und die beste
          Lösung für Sie zu finden.
        </p>
        <p>
          WirkaufendeinHaus.at ist Ihr zuverlässiger Partner mit
          Handschlagqualität!
        </p>
      </Section>
      <ListSection
        sx={{ marginBottom: 7 }}
        background="muted"
        title="Wir kaufen Ihr Haus – Sie brauchen sich um nichts zu kümmern!"
        header="Unser Leistungspaket im Überblick:"
        text={[
          {
            listItem:
              'Schnelle und einfache Abwicklung – direkt vom Sofa aus, ganz ohne Aufwand und Ärger!',
          },
          {
            listItem:
              'Kostenlose Online Immobilienbewertung – mit nur einem Klick zur ersten Einschätzung des Marktwerts Ihrer Immobilie',
          },
          {
            listItem:
              'Auf Wunsch persönliche Objektanalyse vor Ort durch einen regionalen Experten',
          },
          {
            listItem: 'Keine Haftung',
          },
          {
            listItem:
              'Keine zusätzlichen Aufwände wie Entrümpelung oder Sanierungsmaßnahmen wie Ausmalen',
          },
          {
            listItem: 'Rechtsverbindliche Angebotslegung in nur 48 Stunden',
          },
          {
            listItem:
              'Übernahme sämtlicher Formalitäten, Verträge und Behördengänge',
          },
          {
            listItem:
              'Vertragserstellung und Treuhandabwicklung durch einen Notar oder Rechtsanwalt',
          },
          {
            listItem:
              'Optional organisieren wir Ihnen gerne ein Übersiedelungsservice',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-individuelle-beratung" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
